<script>
import Layout from "@/router/layouts/main";
import PageHeader from "@/shared/components/page-header";
import appConfig from "@/app.config";

/**
 * Guide page
 */
export default {
  page: {
    title: "FAQs",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Guides",
      items: [
        {
          text: "Dashbaord",
          href: "/"
        },
        {
          text: "Guides",
          active: true
        }
      ]
    };
  },
  methods: {
    clickOnFAQGuide() {
      this.$router.push("/faq-guide");
    },
    clickOnUploadGuide() {
      this.$router.push("/upload-guide");
    },
    clickOnProjectiveQuestionGuide() {
      this.$router.push("/projective-question-guide");
    },
    clickOnAnalysisGuide() {
      this.$router.push("/analysis-guide");
    }
  }
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-3 animate__animated animate__fadeInUp animate__slower">
        <div
          class="card text-center"
          @click="clickOnFAQGuide()"
          style="cursor: pointer"
        >
          <div class="card-body">
            <img src="@/assets/images/guide1.png" alt class="img-fluid" />
            <div class="card-text mt-4">
              <h5>{{ $t("faqs.faq") }}</h5>
              <p>
                {{ $t("faqs.faqText") }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 animate__animated animate__fadeInUp animate__slower">
        <div
          class="card text-center"
          @click="clickOnUploadGuide()"
          style="cursor: pointer"
        >
          <div class="card-body">
            <img src="@/assets/images/guide2.png" alt class="img-fluid" />
            <div class="card-text mt-4">
              <h5>{{ $t("faqs.guideForUploadProcess") }}</h5>
              <p>
                {{ $t("faqs.guideForUploadProcessText") }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 animate__animated animate__fadeInUp animate__slower">
        <div
          class="card text-center"
          @click="clickOnProjectiveQuestionGuide()"
          style="cursor: pointer"
        >
          <div class="card-body">
            <img src="@/assets/images/guide3.png" alt class="img-fluid" />
            <div class="card-text mt-4">
              <h5>{{ $t("faqs.guideForProjectiveQuestions") }}</h5>
              <p>
                {{ $t("faqs.guideForProjectiveQuestionsText") }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 animate__animated animate__fadeInUp animate__slower">
        <div
          class="card text-center"
          @click="clickOnAnalysisGuide()"
          style="cursor: pointer"
        >
          <div class="card-body">
            <img src="@/assets/images/guide4.png" alt class="img-fluid" />
            <div class="card-text mt-4">
              <h5>{{ $t("faqs.guideForRWAAnalysis") }}</h5>
              <p>
                {{ $t("faqs.guideForRWAAnalysisText") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
